import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import ROUTES from "constants/routes";
import * as R from "react-router-dom";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import LogoHorizontalImg from "assets/logo-horizontal.png";
import { ProfileStatus } from "types/user-data";
import { useSelector } from "react-redux";
import { profileUserStatusSelector } from "redux/selectors";

const Navigation = ({ children }: any) => {
  const profileUserStatus = useSelector(profileUserStatusSelector);

  return (
    <Container style={{ marginBottom: 100, maxWidth: "100%" }}>
      <Navbar bg="light" expand="lg" className="mainMenu">
        <Navbar.Brand>
          <R.Link to={ROUTES.USER_HOME}>
            <img
              src={LogoHorizontalImg}
              height="40"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </R.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto"></Nav>

          {profileUserStatus === ProfileStatus.APROBADO && (
            <Nav style={{ marginRight: 8 }}>
              <NavLink to={ROUTES.USER_HOME}>Inicio</NavLink>
              <NavLink to={ROUTES.USER_SCHEDULE_TRANFSERS}>
                Agendar Traslados
              </NavLink>
              <NavLink to={ROUTES.USER_SCHEDULE_TRANFSERS_POOL}>
                Agendar Traslados Combinados
              </NavLink>
              <NavLink to={ROUTES.USER_SCHEDUL_TRANFSERS_POOL}>
                Agenda De Traslados Combinados
              </NavLink>
              <NavLink to={ROUTES.USER_PRIORITY_TRANFSERS}>
                Asignar Prioridad de Retorno
              </NavLink>
              <NavLink to={ROUTES.USER_PRIORITY}>
                Retornos con Prioridad
              </NavLink>
              <NavLink to={ROUTES.USER_ACTIVE}>Activar Viaje</NavLink>
              <NavLink to={ROUTES.USER_PAYMENTS}>Historial de Pagos</NavLink>
              <NavLink to={ROUTES.USER_MY_PROFILE}>Mi Perfil</NavLink>
              <NavLink to={ROUTES.USER_CARS}>Auto/s</NavLink>
              <NavLink to={ROUTES.USER_CONTRACTS}>Carta Oferta/s</NavLink>
              {/* <NavLink to={ROUTES.USER_STATIONS}>Bases</NavLink> */}
            </Nav>
          )}
          <R.Link to={ROUTES.SIGN_OUT}>
            <ButtonLogOut variant="dark">Salir</ButtonLogOut>
          </R.Link>
        </Navbar.Collapse>
      </Navbar>
      <hr style={{ marginTop: 0 }} />
      {children}
    </Container>
  );
};

const ButtonLogOut = styled(Button)`
  padding: 10px 15px !important;
  font-size: 14px;
  line-height: 14px;
`;

const NavLink = styled(R.NavLink)`
  padding: 10px 15px;
  color: #777 !important;
  font-size: 16px !important;
  &:hover {
    color: #000 !important;
    background-color: #f3f3f3;
    border-radius: 5px;
    text-decoration: none;
  }
`;

export default Navigation;
