import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { FIRESTORE } from "constants/firestore";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { QuizParams, ModuleTransfer } from "types/module-transfer";
import { TransferStage } from "types/transfer";
import { Col } from "react-bootstrap";
import { AsyncButton, H1, H2, Spinner, NoData, H3 } from "components/styles";
import moment from "moment";

const AskReturnWithoutWait = () => {
  const { idModule } = useParams<QuizParams>();
  const [loading, setLoading] = useState(true);
  const [datas, setDatas] = useState<ModuleTransfer>();
  const today = new Date();
  console.log(today);

  console.log(
    datas,
    moment(datas?.destination.time.toDate()).endOf("day") < moment(),
    "item",
    loading
  );
  const data = () => {
    FIRESTORE.DOCUMENT_MODULE_TRANSFER(idModule)
      .get()
      .then((d) => setDatas(d.data()));
  };
  const impossibility =
    datas?.status.stage !== 4 ||
    moment(datas?.destination.time.toDate()).endOf("day") < moment()
      ? true
      : false;
  const requestReturn = async () => {
    setLoading(false);

    data();
    console.log(
      datas!.status.stage === TransferStage.waiting_return_request,
      datas,
      loading
    );
    if (
      idModule !== undefined &&
      datas!.status.stage === TransferStage.waiting_return_request
    ) {
      console.log("pido retorno");
      CALLABLE_FUNCTIONS.PASSENGER_ASK_RETURN({
        idModule: idModule,
        isDriver: false,
      });
    }
  };
  console.log(impossibility);

  /*  useEffect(() => {
    const interval = setInterval(() => {
      moduleData();
    }, 300000);

    return () => clearInterval(interval);
  }, []);
*/
  useEffect(() => {
    console.log("hola");
    data();
  }, [loading]);

  return (
    <div style={{ maxWidth: "100%" }}>
      {impossibility ? (
        <Col xs={12}>
          <H2>No es posible solicitar su traslado</H2>
        </Col>
      ) : loading ? (
        <Col xs={12}>
          <H2>Presiona el botón para solicitar el traslado</H2>
          <div
            style={{
              position: "absolute",
              top: "100px",
              left: "120px",
            }}
          >
            <AsyncButton onClick={requestReturn} variant="success">
              Solicitar Traslado
            </AsyncButton>
          </div>{" "}
        </Col>
      ) : (
        <Col xs={12}>
          <H2>Su traslado ya ha sido solicitado!</H2>
        </Col>
      )}
    </div>
  );
};

export default AskReturnWithoutWait;
