import React from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  TransferRequest,
  Meeting,
  ScheduledTransfer,
  FbDocument,
} from "types/transfer";
import moment from "moment";
import { AsyncButton } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";

const TransferRequestCard = ({
  transfers1,
  transfers2,
  scheduleTransfer,
  order,
  distance1,
  distance2,
  distance3,
  uid,
}: {
  transfers1: {
    ref: FbDocument;
    data: ScheduledTransfer;
  };
  transfers2: {
    ref: FbDocument;
    data: ScheduledTransfer;
  };
  scheduleTransfer: boolean;
  order: string;
  distance1: number;
  distance2: number;
  distance3: number;
  uid: string;
}) => {
  const report = async () => {
    try {
      const pool = true;
      const double = false;
      const d = { transfers2, transfers1, uid, pool, double };

      alert("Traslado agendado");
      setTimeout(() => {}, 1000);
    } catch (e) {
      setTimeout(() => {
        alert("Viaje no disponible");
      }, 1000);
    }
  };
  console.log(transfers1, transfers2, "data");

  return (
    <Card border="secondary" style={{ width: "auto", marginTop: "4%" }}>
      <Card.Header style={{ backgroundColor: "#00426A" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              color: "#D1D3D4",
              fontWeight: "bolder",
            }}
          >
            {dateToText(transfers1.data.origin.time.toDate())}
          </div>

          <span style={{ color: "#D1D3D4", fontWeight: "bold" }}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <p>Cálculo aprox.</p>
                </Tooltip>
              }
            >
              <p>${transfers1.data.pool![0].duration / 2} Km</p>
            </OverlayTrigger>
          </span>
          <span style={{ color: "#D1D3D4", fontWeight: "bold" }}>
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <p>
                    Base traslado 1 ${transfers1.data.info.amount?.amount_pool!}
                  </p>
                  <p>
                    Base traslado 2 ${transfers2.data.info.amount?.amount_pool!}
                  </p>
                  {totalAmount(transfers1.data, transfers2.data).tollsAmount >
                  0 ? (
                    <p>
                      Peaje $
                      {
                        totalAmount(transfers1.data, transfers2.data)
                          .tollsAmount
                      }
                    </p>
                  ) : (
                    ""
                  )}
                </Tooltip>
              }
            >
              <p>${totalAmount(transfers1.data, transfers2.data).total}</p>
            </OverlayTrigger>
          </span>
        </div>
      </Card.Header>
      <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={{ fontWeight: "bold" }}>{`Origen: ${moment(
            transfers1.data.origin.time.toDate()
          ).format("HH:mm")}`}</div>
          <div style={{ fontWeight: "bold", color: "#1877F2" }}>
            {`Destino ${moment(
              transfers1.data.destination.time.toDate()
            ).format("HH:mm")}`}
          </div>
        </div>
        <div
          style={{ borderLeft: "4px solid rgb(0, 66, 106)", height: "auto" }}
        ></div>
        <div
          style={{
            marginLeft: "2%",
            fontWeight: "bold",
            flexShrink: 2,
          }}
        >
          <div>{getAddress(transfers1.data.origin)}</div>
          <br />
          <div>{getAddress(transfers1.data.destination)}</div>
        </div>
      </Card.Body>
      <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div style={{ fontWeight: "bold" }}>{`Origen: ${moment(
            transfers2.data.origin.time.toDate()
          ).format("HH:mm")}`}</div>
          <div style={{ fontWeight: "bold", color: "#1877F2" }}>
            {`Destino ${moment(
              transfers2.data.destination.time.toDate()
            ).format("HH:mm")}`}
          </div>
        </div>
        <div
          style={{ borderLeft: "4px solid rgb(0, 66, 106)", height: "auto" }}
        ></div>
        <div
          style={{
            marginLeft: "2%",
            fontWeight: "bold",
            flexShrink: 2,
          }}
        >
          <div>{getAddress(transfers2.data.origin)}</div>
          <br />
          <div>{getAddress(transfers2.data.destination)}</div>
        </div>
      </Card.Body>
      <Card.Body style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            fontWeight: "bold",
            flexShrink: 2,
          }}
        >
          <div>
            {getOrder(
              transfers1.data,
              transfers2.data,
              order,
              distance1,
              distance2,
              distance3
            )}
          </div>
        </div>
      </Card.Body>
      <Card.Footer>
        {/* <AsyncButton
          style={{ justifyContent: "center" }}
          onClick={report}
          variant="success"
        >
          Devolver Traslados
        </AsyncButton> */}
      </Card.Footer>
    </Card>
  );
};

const dayToString = (m: number) => {
  if (m == 1) return "Lunes";
  if (m == 2) return "Martes";
  if (m == 3) return "Miércoles";
  if (m == 4) return "Jueves";
  if (m == 5) return "Viernes";
  if (m == 6) return "Sábado";
  if (m == 0) return "Domingo";
  return "Error";
};

const monthToString = (m: number) => {
  if (m == 0) return "enero";
  if (m == 1) return "febrero";
  if (m == 2) return "marzo";
  if (m == 3) return "abril";
  if (m == 4) return "mayo";
  if (m == 5) return "junio";
  if (m == 6) return "julio";
  if (m == 7) return "agosto";
  if (m == 8) return "septiembre";
  if (m == 9) return "octubre";
  if (m == 10) return "noviembre";
  if (m == 11) return "diciembre";
  return "Error";
};

const totalAmount = (
  transfer1: ScheduledTransfer,
  transfer2: ScheduledTransfer
) => {
  let tollsAmount1 = 0;
  let tollsAmount2 = 0;
  if (transfer1.tolls !== undefined) {
    tollsAmount1 += transfer1.tolls.data
      .map((t) => {
        if (t.status === 1)
          return t.amount_corrected !== undefined
            ? Number(t.amount_corrected)
            : Number(t.amount);
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }
  if (transfer2.tolls !== undefined) {
    tollsAmount2 += transfer2.tolls.data
      .map((t) => {
        if (t.status === 1)
          return t.amount_corrected !== undefined
            ? Number(t.amount_corrected)
            : Number(t.amount);
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }
  const tollsAmount = tollsAmount1 + tollsAmount2;
  return {
    total: Math.floor(
      transfer1.info.amount!.amount_pool! +
        tollsAmount1 +
        transfer2.info.amount!.amount_pool! +
        tollsAmount2
    ),
    tollsAmount,
  };
};

const dateToText = (d: Date) =>
  dayToString(d.getDay()) +
  " " +
  d.getDate() +
  " " +
  monthToString(d.getMonth());
const getOrder = (
  transfer1: TransferRequest,
  transfer2: TransferRequest,
  order: string,
  distance1: number,
  distance2: number,
  distance3: number
) => {
  if (order === "O2") {
    //mismo destino
    return (
      <div>
        <div>
          <div>{`Ruta:`}</div>
          <div>{`${moment(transfer2.origin.time.toDate()).format("HH:mm")} ${
            transfer2.origin.address.street
          } ${transfer2.origin.address.number}`}</div>
          <div>{`${moment(transfer2.origin.time.toDate())
            .add(distance1, "minutes")
            .format("HH:mm")} ${transfer1.origin.address.street} ${
            transfer1.origin.address.number
          }`}</div>
          <div>{`${moment(transfer2.origin.time.toDate())
            .add(distance1 + distance2, "minutes")
            .format("HH:mm")} ${transfer1.destination.address.street} ${
            transfer1.destination.address.number
          }`}</div>
          <div>{`${moment(transfer2.origin.time.toDate())
            .add(distance1 + distance2 + distance3, "minutes")
            .format("HH:mm")} ${transfer2.destination.address.street} ${
            transfer2.destination.address.number
          }`}</div>
        </div>
      </div>
    );
  } else if (order === "O1" || order === "D1") {
    return (
      <div>
        <div>
          <div>{`Ruta:`}</div>
          <div>{`${moment(transfer1.origin.time.toDate()).format("HH:mm")} ${
            transfer1.origin.address.street
          } ${transfer1.origin.address.number}`}</div>
          <div>{`${moment(transfer1.origin.time.toDate())
            .add(distance1, "minutes")
            .format("HH:mm")} ${transfer2.origin.address.street} ${
            transfer2.origin.address.number
          }`}</div>
          <div>{`${moment(transfer1.origin.time.toDate())
            .add(distance1 + distance2, "minutes")
            .format("HH:mm")} ${transfer1.destination.address.street} ${
            transfer1.destination.address.number
          }`}</div>
          <div>{`${moment(transfer1.origin.time.toDate())
            .add(distance1 + distance2 + distance3, "minutes")
            .format("HH:mm")} ${transfer2.destination.address.street} ${
            transfer2.destination.address.number
          }`}</div>
        </div>
      </div>
    );
  } else if (order === "D2") {
    return (
      <div>
        <div>
          <div>{`Ruta:`}</div>
          <div>{`${moment(transfer1.origin.time.toDate()).format("HH:mm")} ${
            transfer1.origin.address.street
          } ${transfer1.origin.address.number}`}</div>
          <div>{`${moment(transfer1.origin.time.toDate())
            .add(distance1, "minutes")
            .format("HH:mm")} ${transfer2.origin.address.street} ${
            transfer2.origin.address.number
          }`}</div>
          <div>{`${moment(transfer1.origin.time.toDate())
            .add(distance1 + distance2, "minutes")
            .format("HH:mm")} ${transfer2.destination.address.street} ${
            transfer2.destination.address.number
          }`}</div>
          <div>{`${moment(transfer1.origin.time.toDate())
            .add(distance1 + distance2 + distance3, "minutes")
            .format("HH:mm")} ${transfer1.destination.address.street} ${
            transfer1.destination.address.number
          }`}</div>
        </div>
      </div>
    );
  }
};
const getAddress = (z: Meeting): string => {
  /* if (z.address.locality?.length == 3 && z.address.number) {
        return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
      } else */
  const localityToShow =
    z.address.locality && z.address.locality.length <= 3
      ? z.address.district
      : z.address.locality;
  if (z.address.number) {
    return `${z.address.street}, ${z.address.number}, ${localityToShow}, ${z.address.province}`;
  } else if (!z.address.locality && z.address.number) {
    return `${z.address.street}, ${z.address.number}, CABA, ${z.address.province}`;
  } else if (!z.address.number) {
    return `${z.address.street}, S/N, ${localityToShow}, ${z.address.province}`;
  } else {
    return `${z.address.street}, S/N, CABA, ${z.address.province}`;
  }
};

export default TransferRequestCard;
