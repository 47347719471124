import firebase from "firebase/app";
import "firebase/firestore";
import _ from "lodash";
import { FinishStatus, TransferStage } from "types/transfer";
import { FIRESTORE } from "constants/firestore";

export const getModulesByType = (
  type: number,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  if (asc) {
    if (experta) {
      return _getModulesByType(type, true, driver)
        .orderBy("destination.time", "asc")
        .orderBy("origin.time", "asc")
        .orderBy("destination.geohash", "asc");
    } else {
      return _getModulesByType(type, false, driver)
        .orderBy("destination.time", "asc")
        .orderBy("origin.time", "asc")
        .orderBy("destination.geohash", "asc");
    }
  } else {
    if (experta) {
      return _getModulesByType(type, true, driver)
        .orderBy("destination.time", "desc")
        .orderBy("origin.time", "desc")
        .orderBy("destination.geohash", "desc");
    } else {
      return _getModulesByType(type, false, driver)
        .orderBy("destination.time", "desc")
        .orderBy("origin.time", "desc")
        .orderBy("destination.geohash", "desc");
    }
  }
};

const _getModulesByType = (type: number, experta: boolean, driver?: string) => {
  let collection;
  console.log(driver, "aqui");
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver);
    if (experta) {
      collection = collection.where("art.name", "==", "Experta SML");
      console.log("getModulesByType");
    } else {
      collection = collection.where("art.name", "==", "Feleval SRL");
    }
  } else {
    if (experta) {
      collection = FIRESTORE.COLLECTION_TRANSFERS().where(
        "art.name",
        "==",
        "Experta SML"
      );
      console.log("getModulesByType");
    } else {
      collection = FIRESTORE.COLLECTION_TRANSFERS().where(
        "art.name",
        "==",
        "Feleval SRL"
      );
    }
  }

  if (type === 0) return collection;

  if (type === 1)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 2)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 3)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 4)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 5)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 6)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 7)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 8)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 9)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 10)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo);

  if (type === 11)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  throw new Error("Mal transfer type");
};

export const getCollectionSearchByKeyWords = (
  word: string,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  let collection;
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver);
  } else {
    collection = FIRESTORE.COLLECTION_TRANSFERS();
  }
  if (asc) {
    if (experta) {
      return collection
        .where("art.name", "==", "Experta SML")
        .where("keywords", "array-contains", word)
        .orderBy("destination.time", "asc");
    } else {
      return collection
        .where("art.name", "==", "Feleval SRL")
        .where("keywords", "array-contains", word)
        .orderBy("destination.time", "asc");
    }
  } else {
    if (experta) {
      return collection
        .where("art.name", "==", "Experta SML")
        .where("keywords", "array-contains", word)
        .orderBy("destination.time", "desc");
    } else {
      return collection
        .where("art.name", "==", "Feleval SRL")
        .where("keywords", "array-contains", word)
        .orderBy("destination.time", "desc");
    }
  }
};

export const getCollectionByDate = (
  d: Date,
  d2: Date,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  let collection;
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver);
  } else {
    collection = FIRESTORE.COLLECTION_TRANSFERS();
  }
  asc
    ? (collection = collection
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .orderBy("destination.time", "asc"))
    : (collection = collection
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .orderBy("destination.time", "desc"));

  collection = experta
    ? collection.where("art.name", "==", "Experta SML")
    : collection.where("art.name", "==", "Feleval SRL");
  return collection;
};
export const getCollectionByDateKeyWords = (
  d: Date,
  d2: Date,
  word: string,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  console.log("getCollectionByDateKeyWords");
  let collection;
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver);
  } else {
    collection = FIRESTORE.COLLECTION_TRANSFERS();
  }
  if (asc) {
    if (experta) {
      return collection
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .where("keywords", "array-contains", word)
        .where("art.name", "==", "Experta SML")
        .orderBy("destination.time", "asc");
    } else {
      return collection
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .where("keywords", "array-contains", word)
        .where("art.name", "==", "Feleval SRL")
        .orderBy("destination.time", "asc");
    }
  } else {
    if (experta) {
      return collection
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .where("keywords", "array-contains", word)
        .where("art.name", "==", "Experta SML")
        .orderBy("destination.time", "desc");
    } else {
      return collection
        .where(
          "destination.time",
          "<=",
          firebase.firestore.Timestamp.fromDate(d)
        )
        .where(
          "destination.time",
          ">=",
          firebase.firestore.Timestamp.fromDate(d2)
        )
        .where("keywords", "array-contains", word)
        .where("art.name", "==", "Feleval SRL")
        .orderBy("destination.time", "desc");
    }
  }
};

export const getCollectionByDateKeyWordsFilter = (
  d: Date,
  d2: Date,
  word: string,
  type: number,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  let collection;
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver)
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("keywords", "array-contains", word);
  } else {
    collection = FIRESTORE.COLLECTION_TRANSFERS()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      )
      .where("keywords", "array-contains", word);
  }

  collection = !asc
    ? collection.orderBy("destination.time", "desc")
    : collection.orderBy("destination.time", "asc");
  collection = experta
    ? collection.where("art.name", "==", "Experta SML")
    : collection.where("art.name", "==", "Feleval SRL");
  console.log("ESTOY ACÁ!");
  if (type === 0) return collection;

  if (type === 1)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 2)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 3)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 4)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 5)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  console.log("ESTOY ACÁ!");

  if (type === 6)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 7)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 8)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 9)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 10)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo);

  if (type === 11)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  throw new Error("Mal transfer type");
};

export const getCollectionByDateFilter = (
  d: Date,
  d2: Date,
  type: number,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  let collection;
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver)
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      );
  } else {
    collection = FIRESTORE.COLLECTION_TRANSFERS()
      .where("destination.time", "<=", firebase.firestore.Timestamp.fromDate(d))
      .where(
        "destination.time",
        ">=",
        firebase.firestore.Timestamp.fromDate(d2)
      );
  }
  asc
    ? (collection = collection.orderBy("destination.time", "asc"))
    : (collection = collection.orderBy("destination.time", "desc"));
  collection = experta
    ? collection.where("art.name", "==", "Experta SML")
    : collection.where("art.name", "==", "Feleval SRL");
  if (type === 0) return collection;

  if (type === 1)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 2)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 3)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 4)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 5)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 6)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 7)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 8)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "==", TransferStage.finished);

  if (type === 9)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 10)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo);

  if (type === 11)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  throw new Error("Mal transfer type");
};

export const getCollectionByKeyWordsFilter = (
  word: string,
  type: number,
  asc: boolean,
  experta: boolean,
  driver?: string
) => {
  console.log("getCollectionByKeyWordsFilter");
  let collection;
  if (driver) {
    collection = FIRESTORE.COLLECTION_USER_TRANSFERS(driver).where(
      "keywords",
      "array-contains",
      word.trim()
    );
  } else {
    collection = FIRESTORE.COLLECTION_TRANSFERS().where(
      "keywords",
      "array-contains",
      word.trim()
    );
  }

  asc
    ? (collection = collection.orderBy("destination.time", "asc"))
    : (collection = collection.orderBy("destination.time", "desc"));
  collection = experta
    ? collection.where("art.name", "==", "Experta SML")
    : collection.where("art.name", "==", "Feleval SRL");
  if (type === 0) return collection;

  if (type === 2)
    //"Asignado": tiene conductor designado
    return collection.where("status.stage", "in", [
      TransferStage.scheduled,
      TransferStage.going_to_meeting_point,
      TransferStage.origin_to_destination,
      TransferStage.return_requested,
      TransferStage.waiting_return_request,
      TransferStage.waiting_on_meeting_point,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.destination_to_origin,
    ]);

  if (type === 2)
    //"Un tramo finalizado": la ida fue realizada o Negativo, queda pendiente el Retorno ( caso Modulo con o sin espera)
    return collection.where("status.stage", "in", [
      TransferStage.waiting_return_request,
      TransferStage.return_requested,
      TransferStage.going_to_meeting_point_return,
      TransferStage.waiting_on_meeting_point_return,
      TransferStage.searching_driver_return,
      TransferStage.destination_to_origin,
      TransferStage.canceled_on_waiting_return_request,
    ]);

  if (type === 3)
    //"Iniciado": el conductor está en camino al punto de origen
    return collection.where(
      "status.stage",
      "==",
      TransferStage.going_to_meeting_point
    );

  if (type === 4)
    //"En Origen"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_on_meeting_point
    );

  if (type === 5)
    //"En Viaje"
    return collection.where("status.stage", "in", [
      TransferStage.origin_to_destination,
      TransferStage.destination_to_origin,
    ]);

  if (type === 6)
    //"En Espera"
    return collection.where(
      "status.stage",
      "==",
      TransferStage.waiting_return_request
    );

  if (type === 7)
    //"Retorno solicitado": para módulos Con Espera
    return collection.where(
      "status.stage",
      "==",
      TransferStage.return_requested
    );

  if (type === 8)
    //"Informado": durante los 15 minutos de espera de respuesta de la ART
    return collection
      .where("status.active_report", "==", true)
      .where("status.stage", "<=", TransferStage.finished)
      .orderBy("status.stage");

  if (type === 9)
    //"Finalizado": se cumplieron los tramos de ida y/o retorno normalmente
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Exitoso);

  if (type === 10)
    //"Negativo": finalizados negativos
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Negativo);

  if (type === 11)
    //"Cancelado": la ART lo canceló o Satapp informa a la ART que no lo va a poder realizar
    return collection
      .where("status.stage", "==", TransferStage.finished)
      .where("status.finished_status", "==", FinishStatus.Cancelado);

  throw new Error("Mal transfer type");
};
