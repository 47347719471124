import {
  Button,
  H2,
  NoData,
  Padding,
  Searcher,
  Spinner,
} from "components/styles";
import ROUTES from "constants/routes";
import { WithRef } from "controllers/database";

import { getFullAddress } from "controllers/map";

import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row,
  Table as TableB,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ScheduledTransfer, DefinitionsTransferType } from "types/transfer";
import { usePaginatorWithRef } from "util/hooks";
import {
  getCollectionByDate,
  getCollectionByDateFilter,
  getCollectionByDateKeyWords,
  getCollectionByDateKeyWordsFilter,
  getCollectionByKeyWordsFilter,
  getCollectionSearchByKeyWords,
  getModulesByType,
} from "./transfers_reducer";
import { transferStage } from "./view/module/header";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
  faBaby,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIRESTORE } from "constants/firestore";
import { GetProperty } from "../Sales/Sales/view-bill";
import { SelectDriverInputWithoutLabel } from "../Sales/Purchases";

const AdminDriverTransfersPage = () => (
  <MainContainerDriver
    viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
    art={false}
  />
);

export const MainContainerDriver = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const [asc, setAsc] = useState<boolean>(false);
  const [experta, setExperta] = useState<boolean>(true);
  const [artSelected, setArtSelected] = useState<string>("Experta");
  const [driver, setDriver] = useState<string | undefined>(undefined);

  const pageLimit = 30;
  const [items, loading, Pagination, setCollection] = usePaginatorWithRef(
    getModulesByType(0, asc, experta, driver),
    pageLimit
  );
  useEffect(() => {
    if (artSelected === "Experta") {
      setExperta(true);
    } else {
      setExperta(false);
    }
  }, [artSelected]);
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const [searchDate, setSearchDate] = useState<string>("");
  const [searchKeyWord, setSearchKeyWord] = useState<string>("");
  const newDriver = items.length < 20 ? true : false;

  useEffect(() => {
    if (Object.keys(parsed).length === 0) {
      setCollection(getModulesByType(Number(0), asc, experta, driver));
    }
    if (
      parsed.date !== undefined &&
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined
    ) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWordsFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            Number(parsed.filterType),
            asc,
            experta,
            driver
          )
        );
      }
    } else if (parsed.date !== undefined && parsed.filterType !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDateFilter(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            Number(parsed.filterType),
            asc,
            experta,
            driver
          )
        );
      }
    } else if (parsed.date !== undefined && parsed.keyword !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setSearchKeyWord(parsed.keyword as string);
        setCollection(
          getCollectionByDateKeyWords(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            parsed.keyword as string,
            asc,
            experta,
            driver
          )
        );
      }
    } else if (
      parsed.filterType !== undefined &&
      parsed.keyword !== undefined
    ) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionByKeyWordsFilter(
          parsed.keyword as string,
          Number(parsed.filterType),
          asc,
          experta,
          driver
        )
      );
    } else if (parsed.filterType !== undefined) {
      if (parsed.filterType !== undefined)
        setCollection(
          getModulesByType(Number(parsed.filterType), asc, experta, driver)
        );
    } else if (parsed.keyword !== undefined) {
      setSearchKeyWord(parsed.keyword as string);
      setCollection(
        getCollectionSearchByKeyWords(
          parsed.keyword as string,
          asc,
          experta,
          driver
        )
      );
    } else if (parsed.date !== undefined) {
      setSearchDate(parsed.date as string);
      if (parsed.date!.length === 10 || parsed.date!.length === 8) {
        const dateSearch =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY").add(1, "days")
            : moment(parsed.date, "DD/MM/YY").add(1, "days");
        const dateSearch2 =
          parsed.date!.length === 10
            ? moment(parsed.date, "DD/MM/YYYY")
            : moment(parsed.date, "DD/MM/YY");
        setCollection(
          getCollectionByDate(
            dateSearch.toDate(),
            dateSearch2.toDate(),
            asc,
            experta,
            driver
          )
        );
      }
    }
  }, [
    parsed.filterType,
    parsed.keyword,
    parsed.date,
    asc,
    artSelected,
    driver,
    experta,
  ]);

  const changeType = (type: number) => {
    if (searchDate && searchKeyWord) {
      history.push(
        `?filterType=${type}&keyword=${searchKeyWord}&date=${searchDate}`
      );
    } else if (searchDate) {
      history.push(`?filterType=${type}&date=${searchDate}`);
    } else if (searchKeyWord) {
      history.push(`?filterType=${type}&keyword=${searchKeyWord}`);
    } else {
      history.push(`?filterType=${type}`);
    }
  };

  const searchByKeyWord = (s: string) => {
    if (s === "") return changeType(0);
    if (searchDate && parsed.filterType) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${s}&date=${searchDate}`
      );
    } else if (searchDate) {
      history.push(`?keyword=${s}&date=${searchDate}`);
    } else if (parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&keyword=${s}`);
    } else {
      history.push(`?keyword=${s}`);
    }
  };

  const searchByDate = (s: string) => {
    if (s === "") return changeType(0);
    if (searchKeyWord && parsed.filterType) {
      history.push(
        `?filterType=${parsed.filterType}&keyword=${searchKeyWord}&date=${s}`
      );
    } else if (searchKeyWord) {
      history.push(`?keyword=${searchKeyWord}&date=${s}`);
    } else if (parsed.filterType) {
      history.push(`?filterType=${parsed.filterType}&date=${s}`);
    } else {
      history.push(`?&date=${s}`);
    }
  };
  const optionsFilter = [
    "Elegir Etapa (Todas)",
    "Asignados",
    "Un tramo finalizado",
    "Iniciado",
    "En Origen",
    "En Viaje",
    "En Espera",
    "Retorno solicitado",
    "Informado",
    "Finalizado",
    "Negativo",
    "Cancelado",
  ];

  return (
    <Padding pad="5px">
      <Row>
        <H2>Traslados por conductores</H2>
        <Col md={2}></Col>
      </Row>
      <br />
      <Row>
        <Col md={2}>
          <Form.Control
            as="select"
            value={parsed.filterType !== undefined ? parsed.filterType! : "0"}
            onChange={(event) =>
              changeType(parseInt((event.target as HTMLTextAreaElement).value))
            }
          >
            {optionsFilter.map((op, index) => (
              <option key={index} value={index}>
                {op}
              </option>
            ))}
          </Form.Control>
        </Col>
        <Col md={2}>
          <Searcher
            onChange={setSearchDate}
            onSearch={searchByDate}
            placeHolder="Buscar por fecha (21/05/21)"
            value={searchDate}
          />
        </Col>
        <Col md={3}>
          <Searcher
            onChange={setSearchKeyWord}
            onSearch={searchByKeyWord}
            placeHolder="Buscar por palabras clave"
            value={searchKeyWord}
            tooltip={
              <>
                Nombre/teléfono/dni paciente
                <br />
                Nombre del conductor
                <br />
                Nombre art
                <br />
                Siniestro
                <br />
                Cod. Aut
                <br />
                Cod. Experta
                <br />
                NPA
                <br />
                Dirección origen/destino
              </>
            }
          />
        </Col>
        <Col md={2}>
          <Form.Control
            as="select"
            value={artSelected}
            onChange={(e) => setArtSelected(e.target.value)}
          >
            <option value={"Experta"}>Experta SML</option>
            <option value={"Feleval"}>Feleval SRL</option>
          </Form.Control>
        </Col>
        <Col md={3}>
          <SelectDriverInputWithoutLabel onChange={setDriver} value={driver} />
        </Col>
      </Row>
      <br />

      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        <Table hover responsive>
          <thead>
            <Tr>
              <th>Siniestro-Aut/NPA</th>
              <th>Modo</th>
              <th>Etapa</th>
              <th>ART</th>
              <th>Conductor</th>
              <th style={{ padding: ` 0 30px` }}>
                Fecha Hora{" "}
                {asc ? (
                  <FontAwesomeIcon
                    icon={faArrowAltCircleUp}
                    color="black"
                    onClick={() => {
                      setAsc(false);
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faArrowAltCircleDown}
                    color="black"
                    onClick={() => {
                      setAsc(true);
                    }}
                  />
                )}
              </th>
              <th>Paciente</th>
              <th>Origen</th>
              <th>Destino</th>
              {/* <th>Retorno</th> */}
              <th></th>
              <th></th>
            </Tr>
          </thead>
          <tbody>
            {items.map((item) => {
              return (
                <tr
                  //style={pastTransfer.length > 0 ? { color: "green" } : { color: "red" }}
                  key={item.ref.id}
                >
                  <Td style={{ wordBreak: "break-all" }}>
                    {!item.art
                      ? "sin siniestro"
                      : item.art.accident && item.art.authorization
                      ? item.art.accident + "-" + item.art.authorization
                      : item.art.accident
                      ? item.art.accident
                      : item.art.npa
                      ? "NPA: " + item.art.npa
                      : ""}
                  </Td>
                  <Td>
                    {DefinitionsTransferType[item.type] === "Tramo"
                      ? "Tramo"
                      : "Ida y Vuelta"}
                  </Td>
                  <Td>{transferStage(item)}</Td>
                  <Td>{item.art?.name ? item.art.name : "sin ART"}</Td>
                  <Td>
                    <GetFullName item={item} />
                  </Td>

                  <Td></Td>

                  <Td>
                    <Moment
                      date={item.origin.time.toDate()}
                      format="DD/MM/YYYY"
                    />{" "}
                    {"\n"}
                    <Moment
                      style={{ fontWeight: "bold" }}
                      date={item.origin.time.toDate()}
                      format="HH:mm"
                    />
                    {"-"}
                    <Moment
                      date={item.destination.time.toDate()}
                      format="HH:mm"
                    />
                  </Td>
                  <Td>{item.passenger.name}</Td>
                  <Td>{getFullAddress(item.origin)}</Td>
                  <Td>{getFullAddress(item.destination)}</Td>

                  <Td>
                    <Link to={viewTransfer(item.module.id)}>
                      <Button variant="light">Ver</Button>
                    </Link>
                  </Td>
                  <Td>
                    {newDriver === true ? (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>Conductor Nuevo.</Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          icon={faBaby}
                          color="green"
                        />
                      </OverlayTrigger>
                    ) : (
                      ""
                    )}
                  </Td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      <Pagination />
    </Padding>
  );
};

export const GetFullName = ({
  item,
}: {
  item: ScheduledTransfer & WithRef;
}) => {
  return (
    <>
      <GetProperty
        doc={FIRESTORE.DOCUMENT_USER(item.ref.parent.parent!.id)}
        property="surname"
      />{" "}
      <GetProperty
        doc={FIRESTORE.DOCUMENT_USER(item.ref.parent.parent!.id)}
        property="name"
      />
    </>
  );
};
/*
const GetNewDriver =  ({ item }: { item: ScheduledTransfer & WithRef }) => {
  return (
    <>
      <GetNewDrivers
        doc={FIRESTORE.COLLECTION_USER_TRANSFERS(item.ref.parent.parent!.id)
          .where(
            "origin.time",
            "<=",
            FbDate.fromDate(moment().startOf("day").toDate())
          ).where(
            "origin.time",
            ">=",
            FbDate.fromDate(moment().endOf("day").toDate())
          ).get().then((v)=>v.docs)}
        
      />{" "}
      
    </>
  );
};
 */

const Tr = styled.tr`
  th {
    text-align: center;
    vertical-align: middle !important;
  }
`;

const Td = styled.td`
  height: 10px;
`;

const Table = styled(TableB)`
  background-color: #fff;
  /* table-layout: fixed; */
  font-size: 14px;
  thead th {
    color: #909399;
    border-bottom: 0px;
  }
`;

export default AdminDriverTransfersPage;
