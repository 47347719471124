import { TransferRequest, ScheduledTransfer } from "types/transfer";
import _ from "lodash";
import moment from "moment";
import { getDistanceFromLatLonInKm } from "components/Admin/Transfers/view/pool/pool_reducer";

export const doesNotOverlapPool = (
  transferRequests: TransferRequest[],
  scheduledTransfers: ScheduledTransfer[]
) => {
  console.log(transferRequests, "transferRequests");
  console.log(scheduledTransfers, "scheduledTransfers");

  const conbination: any[] = [];
  let key = -1;
  //tiempo max de llegada tarde (min)
  const afterTimeMax = 15;
  //tiempo max de llegada antes de hora en origen
  const beforeTimeMax = 45;
  if (transferRequests.length > 0 && scheduledTransfers.length > 0) {
    //comparo los agendados si pool con los disponibles
    scheduledTransfers.map((schedule, index) => {
      const travel1 = schedule;
      transferRequests.map((transfer, index) => {
        const travel2 = transfer;

        const linealDistanceD1D2: number = getDistanceFromLatLonInKm(
          travel1.destination.geopoint.latitude,
          travel1.destination.geopoint.longitude,
          travel2.destination.geopoint.latitude,
          travel2.destination.geopoint.longitude
        );
        const durationD1D2: number = Math.round(
          (linealDistanceD1D2 * 1.4 * 60) / 30
        );
        console.log(durationD1D2, "durationD1D2"); //los traslados deben ser del mismo día y tienen el mismo destino
        if (
          moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
            moment(travel1.destination.time.toDate()).format("DD/MM/YYYY") &&
          durationD1D2 === 0
        ) {
          console.log("entra");
          //tiempo entre O1 y D1
          const durationO1D1 = Math.round(
            travel1.info.duration_origin_destination
          );
          //Tiempo entre O2 y D2
          const durationO2D2 = Math.round(
            travel2.info.duration_origin_destination
          );
          //Calculo tiempo estimado entre O2 y D1
          const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
            travel1.destination.geopoint.latitude,
            travel1.destination.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO2D1: number = Math.round(
            (linealDistanceO2D1 * 1.4 * 60) / 30
          );

          //Calculo tiempo estimado entre O1 y O2
          const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
            travel1.origin.geopoint.latitude,
            travel1.origin.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO1O2: number = Math.round(
            (linealDistanceO1O2 * 1.4 * 60) / 30
          );
          //Le agrego 15 min al segundo turno turno
          const destination2Max = moment(travel2.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //la hora max a la q puede llegar el 1er paciente
          const destination1Max = moment(travel1.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //resto 45 min al primer turno
          const destination1Min = moment(
            travel1.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");
          //Lo mas temprano que puede llegar el 2do paciente
          const destination2Min = moment(
            travel2.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");
          //Como el destino es el mismo, comprobamos cual es la ruta mas corta
          //O1 -> O2 -> D
          const durationO1O2D = durationO1O2 + durationO2D2;
          //O2 -> O1 -> D
          const durationO2O1D = durationO1O2 + durationO1D1;
          //tiempo max de viaje del pasajero 1
          let durationMaxP1: number = durationO1D1 * 1.5;
          //tiempo max de viaje del pasajero 2
          let durationMaxP2: number = durationO2D2 * 1.5;

          if (durationMaxP1 <= 30) durationMaxP1 = 30;
          if (durationMaxP2 <= 30) durationMaxP2 = 30;

          const timeMax = moment(destination1Min).add(durationD1D2, "minutes");
          const timeMin = moment(destination2Min).subtract(
            durationD1D2,
            "minutes"
          );
          //Primero busca al paciente 2
          if (durationO1O2D > durationO2O1D) {
            //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
            if (
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
              moment(timeMin.toDate()) <= moment(destination1Max.toDate())
            ) {
              //Verificamos que los pacientes no viajen mas tiempo del permitido
              if (
                durationMaxP2 >= durationO2O1D &&
                durationMaxP1 >= durationO1D1
              ) {
                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "O2",
                  true,
                  durationO1O2,
                  durationO2D1,
                  0,
                  durationO2O1D,
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                ]);
              }
            }
          } else {
            if (
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
              moment(timeMin.toDate()) <= moment(destination1Max.toDate())
            ) {
              //Verificamos que los pacientes no viajen mas tiempo del permitido
              if (
                durationMaxP2 >= durationO2D2 &&
                durationMaxP1 >= durationO1O2D
              ) {
                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "O1",
                  true,
                  durationO1O2,
                  durationO2D1,
                  0,
                  durationO1O2D,
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                ]);
              }
            }
          }
        } else if (
          moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
            moment(travel1.destination.time.toDate()).format("DD/MM/YYYY") &&
          durationD1D2 !== 0
        ) {
          //tiempo entre O1 y D1
          const durationO1D1 = Math.round(
            travel1.info.duration_origin_destination
          );
          //Tiempo entre O2 y D2
          const durationO2D2 = Math.round(
            travel2.info.duration_origin_destination
          );

          //Calculo tiempo estimado entre O2 y D1
          const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
            travel1.destination.geopoint.latitude,
            travel1.destination.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO2D1: number = Math.round(
            (linealDistanceO2D1 * 1.4 * 60) / 30
          );

          //Calculo tiempo estimado entre O1 y O2
          const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
            travel1.origin.geopoint.latitude,
            travel1.origin.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO1O2: number = Math.round(
            (linealDistanceO1O2 * 1.4 * 60) / 30
          );
          //Le agrego 15 min al segundo turno turno
          const destination2Max = moment(travel2.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //la hora max a la q puede llegar el 1er paciente
          const destination1Max = moment(travel1.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //resto 45 min al primer turno
          const destination1Min = moment(
            travel1.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");
          //Lo mas temprano que puede llegar el 2do paciente
          const destination2Min = moment(
            travel2.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");

          //O1 -> O2 -> D1
          const durationO1O2D1 = durationO1O2 + durationO2D1;
          //O2 -> D1 -> D2
          const durationO2D1D2 = durationO2D1 + durationD1D2;
          //O1 -> O2 -> D2 - D1
          const durationO1O2D2D1 = durationO1O2 + durationO2D2 + durationD1D2;
          const durationO1O2D1D2 = durationD1D2 + durationO2D1 + durationD1D2;
          //tiempo max de viaje del pasajero 1
          let durationMaxP1: number = durationO1D1 * 1.5;
          //tiempo max de viaje del pasajero 2
          let durationMaxP2: number = durationO2D2 * 1.5;

          if (durationMaxP1 <= 30) durationMaxP1 = 30;
          if (durationMaxP2 <= 30) durationMaxP1 = 30;

          const timeMax = moment(destination1Min).add(durationD1D2, "minutes");
          const timeMin = moment(destination2Min).subtract(
            durationD1D2,
            "minutes"
          );
          //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
          if (
            moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
            moment(timeMin.toDate()) <= moment(destination1Max.toDate())
          ) {
            //Verificamos que los pacientes no viajen mas tiempo del permitido
            if (
              durationMaxP2 >= durationO2D1D2 &&
              durationMaxP1 >= durationO1O2D1 &&
              durationMaxP2 >= durationO2D2 &&
              durationMaxP1 >= durationO1O2D2D1
            ) {
              console.log("2 destinos posibles");

              //verificamos el camino mas corto
              if (durationO1O2D2D1 > durationO1O2D1D2) {
                console.log("camino mas corto por d2");

                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "D2",
                  true,
                  durationO1O2,
                  durationO2D2,
                  durationD1D2,
                  durationO1O2D2D1,
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                ]);
              } else {
                console.log("camio mas corto por D1");
                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "D1",
                  true,
                  durationO1O2,
                  durationO2D1,
                  durationD1D2,
                  durationO1O2D1D2,
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                ]);
              }
            } else if (
              durationMaxP2 >= durationO2D1D2 &&
              durationMaxP1 >= durationO1O2D1
            ) {
              console.log("Camino por D1");

              key = key + 1;
              console.log(key);
              conbination.push([
                key,
                travel1,
                travel2,
                "D1",
                true,
                durationO1O2,
                durationO2D1,
                durationD1D2,
                durationO1O2D1D2,
                {
                  latitude: travel1.origin.geopoint.latitude,
                  longitude: travel1.origin.geopoint.longitude,
                },
                {
                  latitude: travel2.origin.geopoint.latitude,
                  longitude: travel2.origin.geopoint.longitude,
                },
              ]);
            } else if (
              durationMaxP2 >= durationO2D2 &&
              durationMaxP1 >= durationO1O2D2D1
            ) {
              key = key + 1;
              console.log(key);
              conbination.push([
                key,
                travel1,
                travel2,
                "D2",
                true,
                durationO1O2,
                durationO2D2,
                durationD1D2,
                durationO1O2D2D1,
                {
                  latitude: travel1.origin.geopoint.latitude,
                  longitude: travel1.origin.geopoint.longitude,
                },
                {
                  latitude: travel2.origin.geopoint.latitude,
                  longitude: travel2.origin.geopoint.longitude,
                },
              ]);
            }
          }
        }
      });
    });
  }
  //comparo los traslados sin agendar
  if (transferRequests.length > 0 && scheduledTransfers.length == 0) {
    transferRequests.map((transfer, index) => {
      const travel1 = transfer;
      for (let j = index + 1; j < transferRequests.length; j++) {
        const travel2 = transferRequests[j];
        //los traslados deben ser del mismo día y debe tener al menos 1 traslado buscando conductor
        const linealDistanceD1D2: number = getDistanceFromLatLonInKm(
          travel1.destination.geopoint.latitude,
          travel1.destination.geopoint.longitude,
          travel2.destination.geopoint.latitude,
          travel2.destination.geopoint.longitude
        );
        const durationD1D2: number = Math.round(
          (linealDistanceD1D2 * 1.4 * 60) / 30
        );
        console.log(durationD1D2, "durationD1D2");

        if (
          moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
            moment(travel1.destination.time.toDate()).format("DD/MM/YYYY") &&
          durationD1D2 === 0
        ) {
          console.log("Mismo destino");
          //tiempo entre O1 y D1
          const durationO1D1 = Math.round(
            travel1.info.duration_origin_destination
          );
          //Tiempo entre O2 y D2
          const durationO2D2 = Math.round(
            travel2.info.duration_origin_destination
          );

          //Calculo tiempo estimado entre O2 y D1
          const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
            travel1.destination.geopoint.latitude,
            travel1.destination.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO2D1: number = Math.round(
            (linealDistanceO2D1 * 1.4 * 60) / 30
          );

          //Calculo tiempo estimado entre O1 y O2
          const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
            travel1.origin.geopoint.latitude,
            travel1.origin.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO1O2: number = Math.round(
            (linealDistanceO1O2 * 1.4 * 60) / 30
          );
          //Le agrego 15 min al segundo turno
          const destination2Max = moment(travel2.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //la hora max a la q puede llegar el 1er paciente
          const destination1Max = moment(travel1.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //resto 45 min al primer turno
          const destination1Min = moment(
            travel1.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");
          //Lo mas temprano que puede llegar el 2do paciente
          const destination2Min = moment(
            travel2.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");
          //Como el destino es el mismo, comprobamos cual es la ruta mas corta
          //O1 -> O2 -> D
          const durationO1O2D = durationO1O2 + durationO2D2;
          //O2 -> O1 -> D
          const durationO2O1D = durationO1O2 + durationO1D1;
          //O1 -> O2 -> D1
          const durationO1O2D1 = durationO1O2 + durationO2D1;
          //O2 -> D1 -> D2
          const durationO2D1D2 = durationO2D1 + durationD1D2;
          //O1 -> O2 -> D2 - D1
          const durationO1O2D2D1 = durationO1O2 + durationO2D2 + durationD1D2;
          //tiempo max de viaje del pasajero 1
          let durationMaxP1: number = durationO1D1 * 1.5;
          //tiempo max de viaje del pasajero 2
          let durationMaxP2: number = durationO2D2 * 1.5;

          if (durationMaxP1 <= 30) durationMaxP1 = 30;
          if (durationMaxP2 <= 30) durationMaxP1 = 30;

          const timeMax = moment(destination1Min).add(durationD1D2, "minutes");
          const timeMin = moment(destination2Min).subtract(
            durationD1D2,
            "minutes"
          );

          //Primero busca al paciente 2
          if (durationO1O2D > durationO2O1D) {
            //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
            if (
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
              moment(timeMin.toDate()) <= moment(destination1Max.toDate())
            ) {
              //Verificamos que los pacientes no viajen mas tiempo del permitido
              if (
                durationMaxP2 >= durationO2O1D &&
                durationMaxP1 >= durationO1D1
              ) {
                console.log(
                  durationMaxP2 >= durationO2D1D2,
                  durationMaxP1 >= durationO1O2D1
                );

                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "O2",
                  false,
                  durationO1O2,
                  durationO1D1,
                  0,
                  durationO2O1D,
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                ]);
              }
            }
          } else {
            if (
              moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
              moment(timeMin.toDate()) <= moment(destination1Max.toDate())
            ) {
              //Verificamos que los pacientes no viajen mas tiempo del permitido
              if (
                durationMaxP2 >= durationO2D2 &&
                durationMaxP1 >= durationO1O2D
              ) {
                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "O1",
                  false,
                  durationO1O2,
                  durationO2D1,
                  0,
                  durationO1O2D,
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                ]);
              }
            }
          }
        } else if (
          moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
            moment(travel1.destination.time.toDate()).format("DD/MM/YYYY") &&
          durationD1D2 !== 0
        ) {
          //tiempo entre O1 y D1
          const durationO1D1 = Math.round(
            travel1.info.duration_origin_destination
          );
          //Tiempo entre O2 y D2
          const durationO2D2 = Math.round(
            travel2.info.duration_origin_destination
          );

          //Calculo tiempo estimado entre O2 y D1
          const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
            travel1.destination.geopoint.latitude,
            travel1.destination.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO2D1: number = Math.round(
            (linealDistanceO2D1 * 1.4 * 60) / 30
          );

          //Calculo tiempo estimado entre O1 y O2
          const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
            travel1.origin.geopoint.latitude,
            travel1.origin.geopoint.longitude,
            travel2.origin.geopoint.latitude,
            travel2.origin.geopoint.longitude
          );
          const durationO1O2: number = Math.round(
            (linealDistanceO1O2 * 1.4 * 60) / 30
          );
          //Le agrego 15 min al segundo turno turno
          const destination2Max = moment(travel2.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //la hora max a la q puede llegar el 1er paciente
          const destination1Max = moment(travel1.destination.time.toDate()).add(
            afterTimeMax,
            "minutes"
          );
          //resto 45 min al primer turno
          const destination1Min = moment(
            travel1.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");
          //Lo mas temprano que puede llegar el 2do paciente
          const destination2Min = moment(
            travel2.destination.time.toDate()
          ).subtract(beforeTimeMax, "minutes");

          //O1 -> O2 -> D1
          const durationO1O2D1 = durationO1O2 + durationO2D1;
          //O2 -> D1 -> D2
          const durationO2D1D2 = durationO2D1 + durationD1D2;
          //O1 -> O2 -> D2 - D1
          const durationO1O2D2D1 = durationO1O2 + durationO2D2 + durationD1D2;
          const durationO1O2D1D2 = durationD1D2 + durationO2D1 + durationD1D2;
          //tiempo max de viaje del pasajero 1
          let durationMaxP1: number = durationO1D1 * 1.5;
          //tiempo max de viaje del pasajero 2
          let durationMaxP2: number = durationO2D2 * 1.5;

          if (durationMaxP1 <= 30) durationMaxP1 = 30;
          if (durationMaxP2 <= 30) durationMaxP1 = 30;

          const timeMax = moment(destination1Min).add(durationD1D2, "minutes");
          const timeMin = moment(destination2Min).subtract(
            durationD1D2,
            "minutes"
          );
          //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
          if (
            moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
            moment(timeMin.toDate()) <= moment(destination1Max.toDate())
          ) {
            //Verificamos que los pacientes no viajen mas tiempo del permitido
            if (
              durationMaxP2 >= durationO2D1D2 &&
              durationMaxP1 >= durationO1O2D1 &&
              durationMaxP2 >= durationO2D2 &&
              durationMaxP1 >= durationO1O2D2D1
            ) {
              console.log("2 destinos posibles");

              //verificamos el camino mas corto
              if (durationO1O2D2D1 > durationO1O2D1D2) {
                console.log("camino mas corto por d2");
                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "D2",
                  false,
                  durationO1O2,
                  durationO2D2,
                  durationD1D2,
                  durationO1O2D2D1,
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                ]);
              } else {
                console.log("camio mas corto por D1");

                key = key + 1;
                console.log(key);
                conbination.push([
                  key,
                  travel1,
                  travel2,
                  "D1",
                  false,
                  durationO1O2,
                  durationO2D1,
                  durationD1D2,
                  durationO1O2D1D2,
                  {
                    latitude: travel1.origin.geopoint.latitude,
                    longitude: travel1.origin.geopoint.longitude,
                  },
                  {
                    latitude: travel2.origin.geopoint.latitude,
                    longitude: travel2.origin.geopoint.longitude,
                  },
                ]);
              }
            } else if (
              durationMaxP2 >= durationO2D1D2 &&
              durationMaxP1 >= durationO1O2D1
            ) {
              console.log("Camino por D1");

              key = key + 1;
              console.log(key);
              conbination.push([
                key,
                travel1,
                travel2,
                "D1",
                false,
                durationO1O2,
                durationO2D1,
                durationD1D2,
                durationO1O2D1D2,
                {
                  latitude: travel1.origin.geopoint.latitude,
                  longitude: travel1.origin.geopoint.longitude,
                },
                {
                  latitude: travel2.origin.geopoint.latitude,
                  longitude: travel2.origin.geopoint.longitude,
                },
              ]);
            } else if (
              durationMaxP2 >= durationO2D2 &&
              durationMaxP1 >= durationO1O2D2D1
            ) {
              key = key + 1;
              console.log(key);
              conbination.push([
                key,
                travel1,
                travel2,
                "D2",
                false,
                durationO1O2,
                durationO2D2,
                durationD1D2,
                durationO1O2D2D1,
                {
                  latitude: travel1.origin.geopoint.latitude,
                  longitude: travel1.origin.geopoint.longitude,
                },
                {
                  latitude: travel1.origin.geopoint.latitude,
                  longitude: travel1.origin.geopoint.longitude,
                },
              ]);
            }
          }
        }
      }
    });

    console.log(conbination, "com");
  }
  return conbination;
};

/* export const doesNotOverlapBis = (
  transferRequests: any[],
  scheduledTransfers: ScheduledTransfer[]
) => {
  return _.filter(transferRequests, function (transferRequest) {
    return doesntOverlapWithAnotherTransfer(
      transferRequest.data,
      scheduledTransfers
    );
  });
}; */
/* const doesntOverlapWithAnotherTransfer = (
  transferRequest: TransferRequest,
  scheduledTransfers: ScheduledTransfer[],
  authorizedPool?: boolean
) => {
  let allowedToSchedule: boolean = true;
  if (authorizedPool === undefined || authorizedPool === false) {
    for (let i = 0; i < scheduledTransfers.length; i++) {
      const scheduled = scheduledTransfers[i];

      let travel1, travel2;
      if (
        moment(scheduled.destination.time.toDate()) <
        moment(transferRequest.destination.time.toDate())
      ) {
        travel1 = scheduled;
        travel2 = transferRequest;
      } else if (
        moment(scheduled.destination.time.toDate()) >
        moment(transferRequest.destination.time.toDate())
      ) {
        travel1 = transferRequest;
        travel2 = scheduled;
      } else {
        if (
          transferRequest.info.duration_origin_destination >
          scheduled.info.duration_origin_destination
        ) {
          travel1 = transferRequest;
          travel2 = scheduled;
        } else {
          travel1 = scheduled;
          travel2 = transferRequest;
        }
      }

      const linealDistance = getDistanceFromLatLonInKm(
        travel1.destination.geopoint.latitude,
        travel1.destination.geopoint.longitude,
        travel2.origin.geopoint.latitude,
        travel2.origin.geopoint.longitude
      );
      const durationDestination1ToOrigin2 = (linealDistance * 1.4 * 60) / 40;
      if (scheduled.isUrgent) {
        if (transferRequest.isUrgent) {
          // Ya agendado
          // contra
          // Ya Tranfer Request
          // H.Destino1 + Dist.O1~D2 <= H.Destino2 - Dur.2

          allowedToSchedule =
            moment(travel1.destination.time.toDate()).add(
              durationDestination1ToOrigin2,
              "minutes"
            ) <=
            moment(travel2.destination.time.toDate()).subtract(
              travel2.info.duration_origin_destination,
              "minutes"
            );

          if (allowedToSchedule === false) {
            break;
          }
        } else {
          // Ya agendado
          // contra
          // Programado
          // H.Destino1 + Dist.O1~D2 <= H.Destino2 - Dur.2

          allowedToSchedule =
            moment(travel1.destination.time.toDate()).add(
              durationDestination1ToOrigin2,
              "minutes"
            ) <=
            moment(travel2.destination.time.toDate()).subtract(
              travel2.info.duration_origin_destination,
              "minutes"
            );

          if (allowedToSchedule === false) {
            break;
          }
        }
      } else {
        if (transferRequest.isUrgent) {
          //PROGRAMADO CONTRA YA
          // H.Destino1 + Dist.O1~D2 <= H.Destino2 - Dur.2

          allowedToSchedule =
            moment(travel1.destination.time.toDate()).add(
              durationDestination1ToOrigin2,
              "minutes"
            ) <=
            moment(travel2.destination.time.toDate()).subtract(
              travel2.info.duration_origin_destination,
              "minutes"
            );

          if (allowedToSchedule === false) {
            break;
          }
        } else {
          //PROGRAMADO CONTRA PROGRAMADO

          const buffer =
            travel1.info.duration_origin_destination <= 15
              ? 30
              : travel1.info.duration_origin_destination <= 30
              ? 20
              : 0;

          const totalTravelTime =
            travel1.info.duration_origin_destination +
            travel2.info.duration_origin_destination +
            durationDestination1ToOrigin2 +
            buffer;

          allowedToSchedule =
            moment(travel2.destination.time.toDate()).subtract(
              totalTravelTime,
              "minutes"
            ) >= moment(travel1.origin.time.toDate());

          if (allowedToSchedule === false) {
            break;
          }
        }
      }
    }
  }

  return allowedToSchedule;
}; */
